import type { Source, SourceType } from "@/types/types"

interface SourceDetails {
  name: string
  description: string
  docsPath?: string // override path to docs site page
}

export const PULL_BASED_SOURCE_TYPES: SourceType[] = ["cloudtrail"]

export const SOURCE_TYPES: { [K in SourceType]: SourceDetails } = {
  cloudtrail: {
    name: "CloudTrail",
    description: "Ingest events from AWS CloudTrail.",
  },
  datadog_agent: {
    name: "Datadog Agent",
    description: "Process metrics, logs, and traces.",
  },
  elastic: {
    name: "Elastic",
    description: "Accept events via Elasticsearch bulk API.",
  },
  hec: {
    name: "HTTP Event Collector",
    description: "Accept events via Splunk's HTTP Event Collector (HEC) API.",
  },
  http: { name: "HTTP", description: "Ingest data to Streamfold over HTTP." },
  kafka_consumer: { name: "Kafka", description: "TBD" },
  netlify: {
    name: "Netlify",
    description: "Connect Netlify log drains to Streamfold.",
  },
  open_telemetry: {
    name: "OpenTelemetry",
    description: "Send data to Streamfold with OTEL.",
  },
  prometheus_remote_write: {
    name: "Prometheus Remote-Write",
    description: "Receiver for Remote-Write requests.",
  },
}

// Given a source obj or source type, return the path to its SVG image
export const sourceImagePath = (
  source: Source | SourceType | null | undefined
) => {
  if (!source) return ""
  const sType = isSource(source) ? source.type : source
  return `/images/sources/${sType}.svg`
}

// Given a source obj or source type, return the correct user-friendly
// name to display in the UI
export const sourceTypeName = (
  source: Source | SourceType | null | undefined
) => {
  if (!source) return ""
  const sType = isSource(source) ? source.type : source
  const sourceType = sType && SOURCE_TYPES[sType]
  return sourceType?.name || ""
}

export const sourceTypeDescription = (
  source: Source | SourceType | null | undefined
) => {
  if (!source) return ""
  const sType = isSource(source) ? source.type : source
  const sourceType = sType && SOURCE_TYPES[sType]
  return sourceType?.description || ""
}

export const sourceTypeDocsPath = (sType: SourceType): string => {
  if (!sType) return ""
  const sourceDetails = SOURCE_TYPES[sType]
  if (!sourceDetails) return ""
  const lastSegment = sourceDetails.docsPath || sType
  return `sources/${lastSegment}`
}

// type guard to check if object is a source
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSource = (obj: any): obj is Source => {
  // we can't check inclusion of SourceType because it is a string
  // literal union, use SOURCE_TYPES as a proxy
  return typeof obj === "object" && Object.keys(SOURCE_TYPES).includes(obj.type)
}
